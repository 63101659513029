<template>
  <v-card id="card-trip-offers" class="pa-0 elevation-4" >
    <v-card-title class="trip-title pl-4 py-1 pa-0" >
      <v-row>
        <v-col cols="8">
          <h4 class="primary--text" id="title-card">
            <v-icon class="primary--text mr-2">
              fa-solid fa-building
            </v-icon>
            {{ $t("components.views.trip.offers.client.card_driver.trip_no") }}
            <span class="text-title-card">
              {{ dataProp.information.tripNumber }}
            </span>
          </h4>
        </v-col>
        <v-col cols="4" align="right">
          <h4 class="green--text text--darken-3 pr-4  moneytext">
            {{ formatMoney(dataProp.status.amount, dataProp.status.currency) }}
          </h4>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider class="mb-1 custom-divider" />
    <v-card-text class="pl-0 py-2 pr-0">
      <v-container class="pa-0 ma-0">
        <v-row class="justify-start card-height">

          <!-- Columna 1: Route trip -->
          <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="py-2 pl-0 card-route">

            <v-timeline align-top dense  class="pa-0">
                <v-timeline-item  color="secondary"  class="ma-0" small>
                  <v-row>
                    <v-col class="stop-item">
                      <v-chip color="secondary" variant="flat" class="mb-2 card-chip">
                        <p class="ma-0 pa-0 chip-text">
                          {{ $t("components.views.trip.offers.client.card_transport_company.origin")}}
                        </p>
                      </v-chip>
                      <div class="mb-1">
                        <b>
                          <v-icon class="primary--text mr-2" size="small">
                            fa-solid fa-map-marker-alt
                          </v-icon>
                          {{ $t("components.views.trip.offers.client.card_transport_company.city")}}:</b>
                        <span class="route-text">
                          {{ originCity }}
                        </span>
                      </div>
                      <div>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <p id="address" class="mb-1 text" v-bind="attrs" v-on="on">
                              <b>
                                <v-icon class="primary--text mr-2" size="small">
                                  fa-solid fa-map-marked-alt
                                </v-icon>
                                {{ $t("components.views.trip.offers.client.card_transport_company.address")}}:</b>
                              <span class="route-text">
                                {{ originAddress }}
                              </span>
                            </p>
                          </template>
                          <span>{{ dataProp.information.origin.address }}</span>
                        </v-tooltip>
                      </div>
                      <div>
                        <b>
                          <v-icon class="primary--text mr-2" size="small">
                            fa-solid fa-calendar-alt
                          </v-icon>
                          {{ $t("components.views.trip.offers.client.card_transport_company.date")}}:</b>
                        <span class="route-text">
                          {{ dataProp.information.origin.schedule_date }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>

                <v-timeline-item color="secondary" small>
                  <v-row class="pt-1">
                    <v-col class="stop-item">
                      <v-chip color="secondary" variant="flat" class="mb-2 card-chip">
                        <p class="ma-0 pa-0 chip-text">
                          {{ $t("components.views.trip.offers.client.card_transport_company.destiny")}}
                        </p>
                      </v-chip>
                      <div class="mb-1">
                        <b>
                          <v-icon class="primary--text mr-2" size="small">
                            fa-solid fa-map-marker-alt
                          </v-icon>
                          {{ $t("components.views.trip.offers.client.card_transport_company.city")}}:</b>
                        <span class="route-text">
                          {{ destinyCity }}
                        </span>
                      </div>
                      <div>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <p id="address" class="mb-1 text" v-bind="attrs" v-on="on">
                              <b>
                                <v-icon class="primary--text mr-2" size="small">
                                  fa-solid fa-map-marked-alt
                                </v-icon>
                                {{ $t("components.views.trip.offers.client.card_transport_company.address")}}:</b>
                              <span class="route-text">
                                {{ destinyAddress }}
                              </span>
                            </p>
                          </template>
                          <span>{{ dataProp.information.destiny.address }}</span>
                        </v-tooltip>
                      </div>
                      <div>
                        <b>
                          <v-icon class="primary--text mr-2" size="small">
                            fa-solid fa-calendar-alt
                          </v-icon>
                          {{ $t("components.views.trip.offers.client.card_transport_company.date")}}:</b>
                        <span class="route-text">
                          {{ dataProp.information.destiny.schedule_date }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
          </v-col>
 <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="mb-1 info-offers-container">
            <v-row class="justify-center py-0 mt-0 card-info-offers">
              <v-col cols="12" class="py-3 mt-0" >
                  <p class="text">
                    <b
                      >{{
                        $t(
                          "components.views.trip.offers.client.card_transport_company.type_operation"
                        )
                      }}:
                    </b></br>
                    <span class="route-text">
                      {{ dataProp.information.nameTypeOperation }}
                    </span>
                  </p>
                <!-- Fecha y hora de publicación -->
                <p class="text" >
                  <b
                    >{{
                      $t(
                          "components.views.trip.offers.client.card_transport_company.date_publish"
                      )
                    }}:

                </b></br>
                  <span class="route-text">
                    {{
                    moment
                      .utc(dataProp.information.createdOn, "YYYY/MM/DD H:mm")
                      .local()
                      .format("DD/MM/YYYY HH:mm")
                    }}
                  </span>
                </p>
              <!-- Tiempo transcurrido -->
              <p class="my-0 py-0 text">
                  <b
                    >{{
                      $t(
                        "components.views.trip.offers.client.card_transport_company.time_publish"
                      )
                    }}:
                  </b> </br>
                  <span class="route-text">
                    {{ timeElpased(dataProp.information.createdOn) }}
                  </span>
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider class="custom-divider" />

    <v-card-actions class="card-btn-actions pa-0 ma-0">
      <v-container class="ma-0 pa-0">
          <v-row class="justify-center ma-0 pa-0 btn-container">
            <div class="btn-details">
              <v-btn
                variant="outlined"
                color="blue-darken-4 "
                class="card-btn ma-0"
                @click="onOpenInfoOffer()"
              >
                <v-icon class="mr-2" small>fa-solid fa-circle-info</v-icon>
                {{
                  $t(
                    "components.views.trip.offers.client.card_transport_company.detail"
                  )
                }}
              </v-btn>
            </div>
            <div class="btn-requests">
              <v-btn
                class="btn-requests-item ma-0"
                @click="onOpenRequest()"
                :disabled="numberRequest > 0 ? false : true"
              >
                <v-badge
                  :color="numberRequest > 0 ? 'success' : 'tercery'"
                  class="mb-0"
                  :content="numberRequest"
                  offset-x="-4"
                  offset-y="25"
                >
                  <p
                    :class="
                      numberRequest > 0
                        ? 'mb-2 mt-2 primary--text btn-text-requests'
                        : 'btn-text-requests primary--text mb-2 mt-2'
                    "
                    style="color: grey !important;"
                  >
                    {{
                      $t(
                        "components.views.trip.offers.client.card_transport_company.requests"
                      )
                    }}
                  </p>
                </v-badge>
              </v-btn>
            </div>
          </v-row>
      </v-container>
    </v-card-actions>

    <v-dialog v-model="dialogRequest" width="900px" persistent>
      <ModalRequest
        @closeForm="onCloseRequest"
        @updateOffers="onUpdateOffers"
        :idMonitoringOrder="dataProp.idMonitoringOrder"
        :type="dataProp.type"
        :key="index"
      />
    </v-dialog>

    <v-dialog v-model="dialogInfoOffer" width="900px" persistent>
      <ModalDetails
        @closeForm="onCloseInfoOffer"
        :idMonitoringOrder="dataProp.idMonitoringOrder"
        :type="dataProp.type"
        :key="index"
      />
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: "CardTransportCompany",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ModalRequest: () =>
      import(
        /* webpackChunkName: "ModalRequest" */ "@/components/views/Trip/Offers/Client/Modals/ModalRequest/ModalRequest.vue"
      ),
    ModalDetails: () =>
      import(
        /* webpackChunkName: "ModalDetails" */ "@/components/views/Trip/Offers/Client/Modals/ModalDetails/ModalDetails.vue"
      ),
  },
  data() {
    return {
      dialogRequest: false,
      dialogInfoOffer: false,
      index: 0,
    };
  },
  computed: {
    dataProp: {
      get() {
        return this.data;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    title() {
      let val = this.$vuetify.breakpoint.lg
          ? 10
          : this.$vuetify.breakpoint.xl
          ? 20
          : 10,
        title = this.dataProp.information.tripNumber;
      return title.length > val ? title.substr(0, val) + " …" : title;
    },
    destinyCity() {
      return this.dataProp.information.destiny.city == null
        ? ""
        : this.dataProp.information.destiny.city;
    },
    originCity() {
      return this.dataProp.information.origin.city == null
        ? ""
        : this.dataProp.information.origin.city;
    },
    destinyAddress() {
      let address = this.dataProp.information.destiny.address;
      if (address !== null) {
        let val = this.$vuetify.breakpoint.lg
          ? 40
          : this.$vuetify.breakpoint.xl
          ? 50
          : 40;
        return address.length > val ? address.substr(0, val) + " …" : address;
      } else {
        return "";
      }
    },
    originAddress() {
      let address = this.dataProp.information.origin.address;
      if (address !== null) {
        let val = this.$vuetify.breakpoint.lg
          ? 40
          : this.$vuetify.breakpoint.xl
          ? 50
          : 40;
        return address.length > val ? address.substr(0, val) + " …" : address;
      } else {
        return "";
      }
    },
    numberRequest() {
      let request = this.dataProp.activities;
      let result = Object.values(request).filter(
        (estado) =>
          estado.description === "Rejected" ||
          estado.description === "Cancelled" ||
          estado.description === "Confirmed"
      );
      return request.length - result.length > 0
        ? request.length - result.length
        : "0";
    },
  },
  methods: {
    onOpenRequest() {
      this.index += 1;
      this.dialogRequest = true;
    },
    onCloseRequest() {
      this.dialogRequest = false;
    },
    onOpenInfoOffer() {
      this.index += 1;
      this.dialogInfoOffer = true;
    },
    onCloseInfoOffer() {
      this.dialogInfoOffer = false;
    },
    onUpdateOffers() {
      this.$emit("reloadPage");
    },

    timeElpased(CreatedOn) {
      let now = new Date();
      let countFrom = new Date(CreatedOn).getTime();
      let timeDifference = now - countFrom;
      let secondsInADay = 60 * 60 * 1000 * 24;
      let secondsInAHour = 60 * 60 * 1000;
      let days = Math.floor((timeDifference / secondsInADay) * 1);
      let years = Math.floor(days / 365);

      if (years > 1) {
        days = days - years * 365;
      }

      let hours = Math.floor(
        ((timeDifference % secondsInADay) / secondsInAHour) * 1
      );
      let mins = Math.floor(
        (((timeDifference % secondsInADay) % secondsInAHour) / (60 * 1000)) * 1
      );

      let mess_days =
        days > 0
          ? `${days} ${this.$t(
              "components.views.trip.offers.client.card_transport_company.days"
            )} ${this.$t(
              "components.views.trip.offers.client.card_transport_company.and"
            )}`
          : "";

      let hour =
        hours > 0
          ? `${hours} ${this.$t(
              "components.views.trip.offers.client.card_transport_company.hours"
            )}`
          : "";

      if (hour === "") {
        return `${mins} Min(s)`;
      }

      return `${mess_days} ${hour}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './CardTransportCompanyStyle.scss';

.card-chip {
    height: 18px;
}


.chip-text {
  font-size: 12px;
}

 /* Pantallas  grandes (1300px o más) */
 @media (min-width: 1300px) {
      .chip-text {
        font-size: 15px;
      }
      .card-chip {
        height: 20px;
      }

    }

    /* Pantallas extra grandes (1600px o más) */
    @media (min-width: 1600px) {
      .chip-text {
        font-size: 15px;
      }
      .card-chip {
        height: 20px;
      }

    }
</style>
